import { createApp } from 'vue'
import App from './App.vue' 
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import moment from 'moment-timezone';
import { VueReCaptcha } from "vue-recaptcha-v3";

moment.tz.setDefault('Asia/Jakarta');

createApp(App)
.use(VueReCaptcha, { siteKey: "6LdnjY0cAAAAAKvyqd2HQnq57EWgxObeTwZZjRws" })
.mount('#app')
