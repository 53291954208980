<template>
  <div class="container">
    <div v-if="!token" class="card mt-5 mx-auto" style="max-width: 400px">
      <div class="card-body">
        <form ref="form">
          <div class="alert alert-primary" role="alert">No token available !!!</div>
          <label>Enter the password!!!</label>
          <div v-if="messageError" class="alert alert-warning" role="alert">
            {{ messageError }}
          </div>
          <div class="mb-3">
            <label for="inputPassword2" class="visually-hidden">Password</label>
            <input
              v-model="form.password"
              :class="{ 'is-invalid': validationErrors.password }"
              type="password"
              class="form-control"
              id="inputPassword2"
              placeholder="Password"
              required
            />
            <div v-if="validationErrors.password" class="invalid-feedback">
              {{ validationErrors.password }}
            </div>
            <div v-if="!form.password" class="invalid-feedback" />
          </div>
          <div class="d-grid gap-2">
            <button class="btn btn-primary" @click.prevent="validate">Submit</button>
          </div>
        </form>
        <br />
      </div>
    </div>

    <div v-if="token" class="card mt-5 mx-auto" style="max-width: 355px">
      <div v-if="messageSuccess" class="alert alert-success mx-3 my-3" role="alert">
        {{ messageSuccess }}
      </div>

      <figure class="qrcode">
        <vue-qrcode :value="token" :options="{ width: 350 }" />
        <img
          class="qrcode__image"
          src="https://api.smkganesha.sch.id/public/storage/images/bb77400d-331c-4ce5-b38e-d849e614ca33-logo-smk-ganesha-cimanggung.png"
        />
      </figure>
      <div class="card-body">
        <h5 class="card-title text-center">Token -> {{ formatCountdownTime() }}</h5>
        <h2 class="text-center display-3">
          <strong>{{ password }}</strong>
        </h2>
      </div>
    </div>
  </div>
</template>
<script>
import api from "./services/api";
import moment from "moment-timezone";
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
  name: "HomeView",
  data() {
    return {
      form: {
        password: null,
      },

      validationErrors: {
        password: null,
      },

      id: null,
      token: null,
      password: null,

      intervalId: null,
      countdown: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },

      startAt: null,
      timer: null, // Jumlah menit untuk timer
      endAt: null, // End date akan dihitung saat komponen dimount

      countdownFinished: false,
      isRenewTokenPending: false,

      messageSuccess: null,
      messageError: null,
    };
  },

  components: {
    VueQrcode,
  },

  watch: {
    countdownFinished(newValue) {
      if (newValue) {
        if (this.id) {
          this.renewToken();
        }
      }
    },

    messageSuccess(newValue) {
      if (newValue !== "") {
        // Set a timeout to auto-close the success message after 3000 milliseconds (3 seconds)
        setTimeout(() => {
          this.hideMessageSuccess();
        }, 1000);
      }
    },

    messageError(newValue) {
      if (newValue !== "") {
        // Set a timeout to auto-close the success message after 3000 milliseconds (3 seconds)
        setTimeout(() => {
          this.hideMessageError();
        }, 3000);
      }
    },
  },

  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded();
      this.captcha = await this.$recaptcha("login");
    },
    validate() {
      // Reset validation errors
      this.validationErrors.password = null;

      // Check if password is null or empty
      if (!this.form.password) {
        this.validationErrors.password = "Password is required.";
        return;
      }

      // Perform your validation logic here
      if (this.form.password.length < 4) {
        this.validationErrors.password = "Password must be at least 4 characters long.";
        return; // Stop here if there's a validation error
      }

      // Continue with your form submission logic if validation passes
      this.indexToken();
    },

    async indexToken() {
      this.overlay = true;
      try {
        const response = await api.post("/public-get-token", {
          ...this.form,
          captcha: this.captcha,
        });
        this.id = response.data.token.id;
        this.token = response.data.token.token;
        this.password = response.data.token.password;
        this.timer = response.data.token.timer;
        this.startAt = moment(response.data.token.updated_at).valueOf();
        this.messageSuccess = response.data.message;

        localStorage.setItem("OaT", response.data.token.id);
        localStorage.setItem("token", response.data.token.token);
        localStorage.setItem("status", "true");

        this.overlay = false;
        this.stopCountdown();
        this.startCountdown();
        this.recaptcha();
      } catch (error) {
        this.recaptcha();
        this.messageError = error;
      }
    },

    startCountdown() {
      this.countdownFinished = false;
      this.countdownTimer();
    },

    stopCountdown() {
      this.countdownFinished = true;
      clearInterval(this.intervalId);
      this.intervalId = null; // Hapus ID interval
    },

    countdownTimer() {
      if (this.intervalId !== null) {
        // Hentikan interval sebelumnya jika ada
        clearInterval(this.intervalId);
      }

      this.endAt = this.startAt + this.timer * 60 * 1000; // Konversi menit ke milidetik
      this.intervalId = setInterval(() => {
        const now = moment().valueOf();
        let timeRemaining = this.endAt - now;
        timeRemaining = Math.max(timeRemaining, 0);

        if (timeRemaining <= 0 && !this.countdownFinished) {
          clearInterval(this.intervalId);
          this.countdownFinished = true;
        } else {
          const duration = moment.duration(timeRemaining);
          this.countdown.days = duration.days();
          this.countdown.hours = duration.hours();
          this.countdown.minutes = duration.minutes();
          this.countdown.seconds = duration.seconds();
        }
      }, 1000);
    },

    formatCountdownTime() {
      const { hours, minutes, seconds } = this.countdown;
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}`;
    },

    async renewToken() {
      try {
        const response = await api.put(`/renew_token/${this.id}`);
        this.id = response.data.data.id;
        this.token = response.data.data.token;
        this.timer = response.data.data.timer;
        this.password = response.data.data.password;
        this.startAt = moment(response.data.data.updated_at).valueOf();
        this.startCountdown();
        this.messageSuccess = response.data.message;
      } catch (error) {
        console.log(error);
        this.removePassword()
      }
    },

    hideMessageSuccess() {
      this.messageSuccess = ""; // Hide the success message
    },

    hideMessageError() {
      this.messageError = ""; // Hide the success message
    },

    checkToken() {
      const storedTokenId = localStorage.getItem("OaT");
      const storedToken = localStorage.getItem("token");
      const statusStored = localStorage.getItem("status");

      if (statusStored === "true") {
        // Jika ada token di local storage, lakukan sesuatu dengan token

        this.id = storedTokenId;
        this.token = storedToken;
        this.renewToken();
      }
    },

    removePassword() {
      localStorage.removeItem("OaT");
      localStorage.removeItem("token");
      localStorage.removeItem("status");
      this.token = null
    },
  },

  async mounted() {
    await this.recaptcha();
    this.stopCountdown();
    this.checkToken();
  },
};
</script>

<style scoped>
.qrcode {
  display: inline-block;
  font-size: 0;
  position: relative;
}

.qrcode__image {
  background-color: #fff;
  border: 0.25rem solid #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  height: 15%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 15%;
}
</style>
